import { useContext, useEffect } from "react";
import { Navigate } from "react-router";
import { AuthContext, AuthenticationContext } from "../AuthProvider";
import { useGetConfig } from "../../hooks/useGetConfig";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../AddressAndAccountProvider";
import { useNavigate, useLocation } from "react-router-dom";
import BillingProvider from "../BillingProvider";

const ProtectedRoute = ({
  redirectPath = "/",
  children,
}: {
  redirectPath?: string;
  children: React.ReactElement;
}) => {
  const { isAuthenticated } = useContext(AuthContext) as AuthenticationContext;
  const { userInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const navigate = useNavigate();
  const location = useLocation();

  const { data: config } = useGetConfig();
  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;

  const formerLocation = {
    formerLocation: window.location.pathname,
  };

  useEffect(() => {
    const user = userInfo?.data?.user;
    if (user && !user.answered_security_questions) {
      navigate("/security-questions");
    }
  }, [userInfo, navigate, location]);

  if (isAuthenticated === null) {
    return null;
  }

  if (formerLocation?.formerLocation === "/billing" && !showBilling) {
    return <Navigate to={"/"} />;
  }

  if (isAuthenticated === false) {
    return <Navigate to={redirectPath} state={formerLocation} />;
  }

  return <BillingProvider>{children}</BillingProvider>;
};
export default ProtectedRoute;
