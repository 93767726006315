import React from "react";
import NavContainer from "../../components/NavContainer";
import CardWrapper from "../../components/CardWrapper";
import { Grid, Typography, Stack } from "@mui/material";
import { theme } from "../../theme";
import { BillingContext, BillingType } from "../../components/BillingProvider";
import PaymentHistory from "../../components/PaymentHistory/PaymentHistory";
import Button from "../../components/Button";
import { WarningOctagonIcon } from "../../assets/icons";
import MyPlan from "./components/my-plan";
import getREMFromPX from "../../utils/getREMFromPX";
import useViewport from "../../hooks/useViewport";
import useGetThemePath from "../../hooks/useGetThemePath";
import { BackStyle } from "../HelpCenter/Styles";
import { routes } from "../../App";

export const PendingStatus = () => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const { setOpenModal } = React.useContext(BillingContext) as BillingType;
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ flex: 1, paddingTop: getREMFromPX(20) }}
    >
      <Stack flex={1} alignItems="center" pt={getREMFromPX(10)}>
        <WarningOctagonIcon fill={colors.icons.default.secondaryFillColor} />
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: getREMFromPX(20),
            fontWeight: "600",
            fontStyle: "normal",
            textAlign: "center",
            color: colors.form.primary,
          }}
        >
          Verify Your Account
        </Typography>
      </Stack>
      <Stack sx={{ flex: 1.5, justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: getREMFromPX(12),
            fontWeight: "500",
            fontStyle: "normal",
            lineHeight: getREMFromPX(30),
            letterSpacing: 0,
            textAlign: "left",
            paddingBottom: getREMFromPX(10),
            color: colors.form.primary,
          }}
        >
          To verify your account, please update your ACH Payment by confirming
          the two small deposits made to your account. This process may take 1-2
          days. If you require immediate service, we suggest using a credit card
          payment.
        </Typography>
        <Button
          mode="black"
          sx={{ maxWidth: "262px", width: "100%", alignSelf: "flex-end" }}
          text="Verify Payment"
          onClick={() => setOpenModal(true)}
        />
      </Stack>
    </Stack>
  );
};

const Billing = () => {
  const { paymentHistory } = React.useContext(BillingContext) as BillingType;

  const { isMobile } = useViewport(900);

  const planStatus = paymentHistory.length
    ? paymentHistory[0].status
    : "Overdue";

  return (
    <NavContainer title="Billing">
      <Grid
        container
        spacing={isMobile ? 0 : 2}
        gap={isMobile ? 2 : 0}
        sx={{ width: "100%" }}
      >
        <Grid xs={12} lg={12}>
          <CardWrapper
            title="My Plan"
            status={planStatus}
            actions={
              <BackStyle
                aria-label="Manage Payments"
                to={routes.myServices.path}
              >
                Manage Payments
              </BackStyle>
            }
          >
            <MyPlan />
          </CardWrapper>
        </Grid>

        <Grid xs={12} lg={12} mt={4}>
          <CardWrapper title="Payment History" hasOverflow>
            <PaymentHistory data={paymentHistory} />
          </CardWrapper>
        </Grid>
      </Grid>
    </NavContainer>
  );
};

export default Billing;
